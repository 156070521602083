.overview-container {
  text-align: center;
}

.items-list {
  width:45%;
  background-color: var(--vai-color-accent-dark-20);
  justify-content: space-around;
  color: white
}
