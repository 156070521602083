#terminal-error
{
    color: #df1616;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
    font-size: 2em;
}

#terminal-buttons
{
    margin-bottom: 20px;
    text-align: center;
}