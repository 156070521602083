body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.rotating-color-text {
  color: white;
  /* animation: wheelHueColor 5s infinite; */
  margin: auto;
  background: hsl(202, 72%, 22%);
  padding: 1em;
  margin-bottom: -7px;
  width: 190px;
}

.rotating-color-background {
  color: hsl(324, 70%, 45%);
  animation: wheelHueColor 10s infinite;
  background-color: currentColor;
  min-height: 100vh;
  display: flex;
}

@supports (color: overlay) {
  .rotating-color-text {
    color: linear-gradient(45deg, white 10%, black 90%);
    background-blend-mode: overlay;
  }
}
@keyframes wheelHueColor {
  from, to { color: hsl(324, 70%, 45%); }
  10%      { color: hsl(360, 65%, 45%); }
  20%      { color: hsl( 36, 80%, 45%); }
  30%      { color: hsl( 72, 75%, 35%); }
  40%      { color: hsl(108, 65%, 35%); }
  50%      { color: hsl(144, 75%, 35%); }
  60%      { color: hsl(180, 75%, 35%); }
  70%      { color: hsl(216, 60%, 45%); }
  80%      { color: hsl(252, 65%, 50%); }
  90%      { color: hsl(288, 60%, 40%); }
}